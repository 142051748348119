import './NewLogin.css'
import { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import React from 'react'
import axios from 'axios'
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { authContext } from '../Context/AuthContext';
import { globalContext } from '../Context/GlobalContext';
import BtnSpinner from '../Spinner/Spinner';
import { useTranslation } from 'react-i18next';

const urlApiLogin = process.env.REACT_APP_API_LOGIN;
const urlApiClientes = process.env.REACT_APP_API_CLIENTE;

const NewLogin = () => {

	const { token, setToken, setUser, setIdUser } = useContext(authContext)
	const { colorTheme } = useContext(globalContext)
	const [datosLogin, setDatosLogin] = useState({})
	const navigate = useNavigate();
	const [showPassword, setShowPassword] = useState(false);
	const [loggin, setLoggin] = useState(false);

	const [t] = useTranslation("global")

	useEffect(() => {
		if (token) {
			navigate(-1)
		}
	}, [token])


	const inputChange = (e) => {
		let name = e.target.name
		let value = e.target.value
		setDatosLogin(values => ({ ...values, [name]: value }))
	}

	const userBadGateway = (_token) => {
		axios.post(`${urlApiLogin}/logout`,
			null,
			{
				headers: { 'Authorization': 'Bearer ' + _token }
			})
			.then(setToken())
			.then(localStorage.clear())
			.then(() => console.log('logout ok!'))
			.then(toast.error('No se pudo completar su solicitud. Intente nuevamente más tarde.', {
				autoClose: 500,
			}))
			.catch(error => console.log('Error de deslogueo', error.response));
	}

	async function getDataUser(_token) {
		try {
			const resp = await axios.get(`${urlApiClientes}`, {
				headers: {
					'Authorization': 'Bearer ' + _token
				}
			})
			const data = resp.data.cliente;
			console.log('👉 data ▶', data);
			return {
				Id: data.Id,
				Cliente: data.Cliente,
				Email: data.Email,
				Nombre: data.Nombre,
				Apellido: data.Apellido,
				Deshabilitado: 0,
				NomCliente: data.NomCliente,
				Cuit: data.Cuit,
				codIIBB: data.codIIBB,
				nroIIB: data.nroIIB,
				Domicilio_facturacion: {
					Domicilio: data.Domicilio_facturacion.Domicilio,
					Localidad: data.Domicilio_facturacion.Localidad,
					Codigo_postal: data.Domicilio_facturacion.Codigo_postal,
					Codigo_Prov: data.Domicilio_facturacion.Codigo_Prov,
					Pais: data.Domicilio_facturacion.Pais,
					Telefono: data.Domicilio_facturacion.Telefono
				},
				Domicilio_entrega: {
					Domicilio: data.Domicilio_entrega?.Domicilio ?? null,
					Localidad: data.Domicilio_entrega?.Localidad ?? null,
					Codigo_postal: data.Domicilio_entrega?.Codigo_postal ?? null,
					Codigo_Prov: data.Domicilio_entrega?.Codigo_Prov ?? null,
					Pais: data.Domicilio_entrega?.Pais ?? null,
					Provincia: {
						Codigo: data.Domicilio_entrega?.Provincia?.Codigo ?? null,
						Descripcion: data.Domicilio_entrega?.Provincia?.Descripcion ?? null
					}
				},
				Condicion_Pago: {
					Codigo: data.Condicion_Pago.Codigo,
					Descripcion: data.Condicion_Pago.Descripcion
				},
				Tipo_entrega: {
					Codigo: data.Tipo_entrega.Codigo,
					Descripcion: data.Tipo_entrega.Descripcion
				},
				Cotizacion: data.Cotizacion,
			}
		}
		catch (err) {
			console.log('err!!', err.response);
		}
	}


	async function logguearse(e) {
		e.preventDefault();
		setLoggin(true);
		try {
			const resp = await axios.post(`${urlApiLogin}/login`, datosLogin);
			const user = await getDataUser(resp.data.token);
			if (!user) {
				return userBadGateway(resp.data.token);
			} else {
				setToken(resp.data.token);
				setIdUser(resp.data.id);
				setUser(user);
				toast.success(`${t("login.bienvenido")} ${user.Nombre}`, {
					position: "top-right",
					className: 'custom-toast',
					autoClose: 1500,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: "light",
				});
			}
		} catch (err) {
			console.log(err.response);
			if (err?.response?.status === 429) {
				const minutes = 5;
				let timerInterval;
				Swal.fire({
					title: t("login.muchos-intentos"),
					html: `Se han realizado demasiadas solicitudes en un periodo corto de tiempo.
          <br> Por favor, inténtalo de nuevo en <b>${minutes}</b> segundos (5 minutos).
          <hr><Small>Este mensaje se cerrara automaticamente cuando la web este lista para avanzar</Small>`,
					icon: 'warning',
					timer: 300000, // 300000 milisegundos (5 minutos)
					timerProgressBar: true,
					didOpen: () => {
						const b = Swal.getHtmlContainer().querySelector('b');
						const startTime = Date.now();
						timerInterval = setInterval(() => {
							const elapsedTime = Date.now() - startTime;
							const remainingTime = 300000 - elapsedTime;
							b.textContent = Math.ceil(remainingTime / 1000); // Muestra el tiempo restante en segundos
						}, 100);
					},
					willClose: () => {
						clearInterval(timerInterval);
					},
				});
			} else if (err?.response?.status === 399) {
				Swal.fire('Documentación vencida', String("Su documentación impositiva está vencida. Para poder ingresar a la web, por favor, ponerse en contacto con su vendedor asignado."), 'error');
				/* Swal.fire('Error al loguearse', String(err?.response?.data?.message), 'error'); */
				/* toast.error(`Error al loguearse: ${err?.response?.data?.message}`); */
			} else if (err?.response?.status === 409) {
				Swal.fire('Usuario ya conectado', String("No es posible loguearse ya que posee una sesión activa en otro dispositivo."), 'error');
			} else if (err?.response?.status === 500) {
				toast.error('No se pudo completar su solicitud. Intente nuevamente más tarde.', {
					autoClose: 1000, // Tiempo de cierre automático en milisegundos (opcional)
				});
				// Swal.fire('Error del servidor', 'No se pudo completar su solicitud. Intente nuevamente más tarde.', 'error');
			}
			setLoggin(false);
		}
	}


	// Muestro password 
	const handleShowPassword = () => {
		!showPassword ? setShowPassword(true) : setShowPassword(false);
	}

	return (
		<div className={`container-fluid d-flex justify-content-center align-items-center bg-${colorTheme}`}>
			<div className="row p-3">
				<div className="col-md-6">
					<div className="row align-items-center">

						<form onSubmit={logguearse} action="">
							<div className={`header-text mb-4 text-${colorTheme === 'dark' ? 'light' : 'dark'}`}>
								<h4 style={{ textTransform: "uppercase" }}>{t("login.clientes-registrados")}</h4>
								<p>{t("login.datos-necesarios")}</p>
							</div>
							<div className="input-container">
								<input
									value={datosLogin.email || ""}
									onChange={inputChange}
									name="email"
									type="email" required
									className='form-control form-control-lg bg-light fs-6' placeholder='Email...' />
							</div>
							<div className="input-container">
								<input
									value={datosLogin.password || ""}
									onChange={inputChange}
									type={!showPassword ? "password" : "text"} name="password"
									required
									className='form-control form-control-lg bg-light fs-6' placeholder={t("login.contraseña")} />
								<button className="invite-btn" type="button" onClick={handleShowPassword}>
									{!showPassword ? <i className="fa-solid fa-eye"></i> : <i className="fa-solid fa-eye-slash"></i>}
								</button>
							</div>
							<div className="input-group mb-5 d-flex justify-content-between">
								<div className="forgot">
									<small>
										<Link to="/forgotPassword">¿{t("login.olvida-contraseña")}?</Link>
									</small>
								</div>
							</div>

							<div className="input-group mb-3">
								{loggin
									? <button disabled className='button-login-screen-new2'><span className="mx-5">{t("login.iniciando")} ...</span> <BtnSpinner /></button>
									: <button type='submit' className='button-login-screen-new'>{t("login.iniciar-sesion")}</button>
								}
							</div>
						</form>
					</div>
				</div>
				<div className="col-md-6">
					<div className="row align-items-center">
						<div className={`header-text mb-4 text-${colorTheme === 'dark' ? 'light' : 'dark'}`}>
							<h4 style={{ textTransform: "uppercase" }}>{t("login.nuevo-cliente")}</h4>
							<p>{t("login.texto-nuevo-cliente")}</p>
						</div>
						<div className="input-group mb-3">
							<button onClick={() => navigate('/contacto')} className='button-login-screen-new'>{t("login.crear-cuenta")}</button>
						</div>
					</div>
				</div>
			</div>

		</div>
	)
}

export default NewLogin