import React, { useState, useEffect, useContext } from 'react'
import { useParams } from 'react-router-dom'
import { Container, Row, Pagination, Col, OverlayTrigger, Tooltip } from 'react-bootstrap'
import axios from 'axios'
import CardMarcas from "../CardMarcas/CardMarcas"
import Tables from '../Tables/Tables'
import { ImagenesMarcas } from './ImagenesMarcas'
import { BsFillGrid1X2Fill } from 'react-icons/bs'
import { FaThList } from 'react-icons/fa'
import "./CardStyle.css"
import { authContext } from '../Context/AuthContext'
import { globalContext } from '../Context/GlobalContext'
import { SpinnerMarcas } from '../helpers/SpinnerMarcas'
import { useTranslation } from 'react-i18next'
import { MdDownload } from "react-icons/md";
import { generarExcel } from '../../utilities/helpers/generadorExcel'

const apiProductos = process.env.REACT_APP_API_PRODUCTOS

export const ApiMap = () => {

  const { id } = useParams();
  const [idFound, setIdFound] = useState();
  const { token } = useContext(authContext);
  const { colorTheme, handleDisplayGrid, handleDisplayList, grid } = useContext(globalContext);
  const [articulosXMarca, setArticulosXMarca] = useState([]);
  const [largoTotal, setLargoTotal] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [load, setLoad] = useState(false);
  const elementosPorPagina = 20;
  const cantidadDePaginas = Math.ceil(largoTotal / elementosPorPagina);
  const country = process.env.REACT_APP_COUNTRY
  const [t] = useTranslation("global")


  const [min, setMin] = useState({});
  const [max, setMax] = useState({});
  const [sentido, setSentido] = useState({});
  const [filter, setFilter] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [showOnlyStock, setShowOnlyStock] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [descargado, setDescargado] = useState(false);



  const borrarCampos = () => {
    setMin({});
    setMax({});
    setSentido({});
    const idValue = document.getElementById('sentido');
    if (!idValue) return false
    idValue.selectedIndex = '';
  }

  const obtenerDatos = async () => {
    try {
      setLoad(false);
      // if ((country === "USA") || (country === "ARG" && token)) {
      if (token) {
        const stockFilter = showOnlyStock ? 1 : 0;

        const response = await axios.get(`${apiProductos}/info/marca/${id}?Stock=${stockFilter}&limit=${elementosPorPagina}&offset=${elementosPorPagina * (currentPage - 1)}`, {
          headers: { 'Authorization': 'Bearer ' + token }
        });

        console.log("USA response", response);
        setArticulosXMarca(response.data.articulos);
        setLargoTotal(response.data.length);

      } else {
        const response = await axios.get(`${apiProductos}/info/marca/${id}?limit=${elementosPorPagina}&offset=${elementosPorPagina * (currentPage - 1)}`, {
          headers: { 'Authorization': 'Bearer ' + token }
        });

        console.log(response);
        setArticulosXMarca(response.data.articulos);
        setLargoTotal(response.data.length);
      }

      setTimeout(() => {
        setLoad(true);
      }, 1000);

      setIdFound(id);

    } catch (error) {
      console.log(error);
      setLoad(true);
    }
  };

  const filtrarPrecios = async () => {
    if (!filter) setCurrentPage(1);
    setFilter(true);

    try {
      let response;
      const stockFilter = showOnlyStock ? 1 : 0;

      if ((country === "USA") || (country === "ARG" && token)) {
        // Filtro de precios y stock para USA
        response = await axios.get(`${apiProductos}/info/marca/${id}?minPrice=${min.minPrice}&maxPrice=${max.maxPrice}&sentido=${sentido.sentido}&Stock=${stockFilter}&limit=${elementosPorPagina}&offset=${elementosPorPagina * (currentPage - 1)}`, {
          headers: { 'Authorization': 'Bearer ' + token }
        });
      } else {
        // Filtro de precios sin stock para el resto de sucursales por ahora
        response = await axios.get(`${apiProductos}/info/marca/${id}?minPrice=${min.minPrice}&maxPrice=${max.maxPrice}&sentido=${sentido.sentido}&limit=${elementosPorPagina}&offset=${elementosPorPagina * (currentPage - 1)}`, {
          headers: { 'Authorization': 'Bearer ' + token }
        });
      }

      console.log(response);
      setArticulosXMarca(response.data.articulos);
      setLargoTotal(response.data.length);
      setLoad(true);
      setIdFound(id);

    } catch (err) {
      console.log(err);
    }
  };


  useEffect(() => {
    if (id !== idFound) {
      setCurrentPage(1)
    }
    if (filter && id === idFound) filtrarPrecios()
    else {
      setFilter(false)
      obtenerDatos();
      borrarCampos();
    }
  }, [id, currentPage, showOnlyStock]);

  useEffect(() => {
    const isMobileOnLoad = window.innerWidth < 670;
    setIsMobile(isMobileOnLoad);
    const handleResize = () => {
      setIsMobile(window.innerWidth < 670);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);


  const paginas = [];
  function renderPagination() {
    for (let i = 1; i <= cantidadDePaginas; i++) {
      paginas.push(
        <Pagination.Item key={i} active={i === currentPage} onClick={() => setCurrentPage(i)}>
          {i}
        </Pagination.Item>
      );
    }
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  useEffect(() => {
    if (articulosXMarca && articulosXMarca.length > 0) {
      setDescargado(false);
    }
  }, [articulosXMarca]);

  return (
    <div className={`api-map-container bg-${colorTheme}`}>
      <ImagenesMarcas id={id} />
      <Container fluid className='py-5' style={{ width: "100%" }}>

        {!isMobile && (
          <div className="display-select-container">
            <Col className={`d-flex justify-content-end text-${colorTheme === 'dark' ? 'light' : 'dark'}`}>
              <div className="row">
                <div className="span6" style={{ width: "115px" }}>
                  <div className="mycontent-left text-center">
                    <h6 className="text-uppercase">{t("producto.ver-como")}</h6>
                    <div className="d-flex flex-col justify-content-around gap-1">
                      <div
                        className={`d-flex flex-row align-items-center svgGrilla ${!grid ? 'activeView' : ''}`}
                        onClick={handleDisplayList}>
                        <FaThList size={"1.5em"} />
                      </div>
                      <div
                        className={`d-flex flex-row align-items-center svgGrilla ${grid ? 'activeView' : ''}`}
                        onClick={handleDisplayGrid}
                      >
                        <BsFillGrid1X2Fill size={"1.5em"} />
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </div>
        )}


        <div style={{ display: !isMobile && 'flex', justifyContent: 'space-between' }}>
          {/* Primera sección: country === "USA" */}
          {(country === "USA" || (country === "ARG" && token)) && (
            // {(token) && (

            <>
              <div className="display-select-container" style={{ flex: 1, textAlign: 'center' }}>
                <h3 style={{ marginBottom: '20px', color: colorTheme === 'dark' ? '#fff' : '#000' }}>
                  Ver:
                </h3>
                <Col
                  className={`d-flex justify-content-center text-${colorTheme === 'dark' ? 'light' : 'dark'}`}
                >
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                      {/* Opción: Con Stock */}
                      <p
                        style={{
                          cursor: 'pointer',
                          color: showOnlyStock ? 'red' : (colorTheme === 'dark' ? '#fff' : '#6c757d'),
                          fontSize: '18px',
                          fontWeight: 'bold',
                          transition: 'color 0.3s, text-decoration 0.3s',
                          textDecoration: showOnlyStock ? 'underline' : 'none',
                        }}
                        onClick={() => setShowOnlyStock(true)}
                        onMouseEnter={(e) => {
                          if (!showOnlyStock) {
                            e.currentTarget.style.color = '#6c757d';
                            e.currentTarget.style.textDecoration = 'underline';
                          }
                        }}
                        onMouseLeave={(e) => {
                          if (!showOnlyStock) {
                            e.currentTarget.style.color = '#6c757d';
                            e.currentTarget.style.textDecoration = 'none';
                          }
                        }}
                      >
                        Productos en stock
                      </p>

                      {(country === "USA" || (country === "ARG" && token && showOnlyStock)) && (
                        <OverlayTrigger
                          placement="right"
                          overlay={
                            <Tooltip id="tooltip-descargar">
                              Descargar articulos
                            </Tooltip>
                          }
                        >
                          <button
                            onClick={() => generarExcel(articulosXMarca, setDescargado)}
                            style={{
                              cursor: "pointer",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              width: "30px",
                              height: "30px",
                              borderRadius: "50%",
                              backgroundColor: "#007bff",
                              border: "none",
                              color: "#fff",
                              boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.2)",
                              transition: "background-color 0.3s",
                              marginBottom: "8px",
                            }}
                            onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = "#0056b3")}
                            onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = "#007bff")}
                          >
                            <MdDownload size={20} />
                          </button>
                        </OverlayTrigger>
                      )}
                    </div>



                    {/* Opción: Sin Stock */}
                    <p
                      style={{
                        cursor: 'pointer',
                        color: !showOnlyStock ? 'red' : (colorTheme === 'dark' ? '#fff' : '#6c757d'),
                        fontSize: '18px',
                        textAlign: 'left',
                        fontWeight: 'bold',
                        transition: 'color 0.3s, text-decoration 0.3s',
                        textDecoration: !showOnlyStock ? 'underline' : 'none',
                      }}
                      onClick={() => setShowOnlyStock(false)}
                      onMouseEnter={(e) => {
                        if (showOnlyStock) {
                          e.currentTarget.style.color = '#6c757d';
                          e.currentTarget.style.textDecoration = 'underline';
                        }
                      }}
                      onMouseLeave={(e) => {
                        if (showOnlyStock) {
                          e.currentTarget.style.color = '#6c757d';
                          e.currentTarget.style.textDecoration = 'none';
                        }
                      }}
                    >
                      Todos los productos
                    </p>
                  </div>
                </Col>


              </div>
            </>
          )}

          {/* Segunda sección */}
          <div style={{ flex: 4 }}>
            {!load ? (
              <SpinnerMarcas text={'Cargando marca'} />
            ) : (
              <Row className="mt-2" style={{ justifyContent: "center" }}>
                <Col xs={12} md={12} sm={6} xl={9} lg={12} style={{ display: "flex", justifyContent: "center" }}>
                  {articulosXMarca.length <= 0 ? (
                    <h3>{t("producto.no-existe")}</h3>
                  ) : isMobile ? (
                    <div className="taleShadow bg-light card-container">
                      <CardMarcas articulosXMarca={articulosXMarca} style={{ display: "flex", justifyContent: "center" }} />
                      <Pagination>{paginas}</Pagination>
                      <div>
                        {t("producto.pagina")} {currentPage} {t("producto.de")} {cantidadDePaginas}
                      </div>
                      {renderPagination()}
                    </div>
                  ) : grid ? (
                    <div className="taleShadow bg-light card-container">
                      <CardMarcas articulosXMarca={articulosXMarca} style={{ display: "flex", justifyContent: "center" }} />
                      <Pagination>{paginas}</Pagination>
                      <div>
                        {t("producto.pagina")} {currentPage} {t("producto.de")} {cantidadDePaginas}
                      </div>
                      {renderPagination()}
                    </div>
                  ) : (
                    <div className="taleShadow bg-light">
                      <Tables articulosXMarca={articulosXMarca} header={["", ""]} />
                      <Pagination>{paginas}</Pagination>
                      <div>
                        {t("producto.pagina")} {currentPage} {t("producto.de")} {cantidadDePaginas}
                      </div>
                      {renderPagination()}
                    </div>
                  )}
                </Col>
              </Row>
            )}
          </div>
        </div>

      </Container>
    </div>
  )
}
